<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <a
        class="no_trad is-flex is-justify-content-center"
        onclick="document.dispatchEvent(new CustomEvent('BurgerMenu__Open',{detail:{}}));"
        role="button"
        aria-label="Navigation menu toggle"
      >
        <img
          class="ml-4"
          src="@/assets/images/icons/menu.svg"
          width="30px"
          alt="Navigation menu toggle icon"
        />
      </a>
      <a class="navbar-item" href="https://search.lilo.org">
        <figure>
          <img
            src="@/assets/images/logo.png"
            alt="Link to search engine home"
          />
        </figure>
      </a>
      <div
        v-if="!mainStore.userApiError"
        id="menus"
        class="navbar-item pt-3 is-align-items-flex-start"
      >
        <a
          class="px-2 is-rounded mr-2"
          onclick="document.dispatchEvent(new CustomEvent('DropMenu__Open',{detail:{}}));window.clarity('event', 'click_navbar-drops-button');"
          role="button"
          aria-label="Drops menu toggle"
        >
          <img
            class="icon-text pt-1 mr-1"
            src="@/assets/images/icons/drop-white.svg"
            alt="Drops menu toggle icon"
          />
          <span class="has-text-weight-bold has-text-white is-size-6">{{
            dropsCount
          }}</span>
        </a>
        <a
          id="notification-mail-button"
          class="lilo-front-notification-mail-button no_trad mx-0"
          role="button"
          aria-label="Notifications menu toggle"
          onclick="window.clarity('event', 'click_navbar-notification-button');"
        >
          <img
            src="@/assets/images/icons/bell.svg"
            alt="Notifications menu toggle icon"
          />
        </a>
        <a href="/mon-compte" class="no_trad">
          <span
            class="dot"
            :class="{
              'has-background-secondary': connected,
              'has-background-grey-light': !connected,
            }"
          />
          <!--          heroicons:user-circle-solid-->
          <img
            src="@/assets/images/icons/user.png"
            width="24"
            alt="Link to my account page"
          />
        </a>
      </div>
    </div>
    <SearchBar class="px-3" />
    <div id="tabs" class="is-flex mt-2 px-4">
      <a
        :href="'/?q=' + encodeURIComponent(queryStore.q)"
        :class="{
          active: queryStore.action === 'web',
        }"
      >
        {{ translate('tabs.web') }}
      </a>
      <a
        :href="'/?action=image&q=' + encodeURIComponent(queryStore.q)"
        :class="{
          active: queryStore.action === 'image',
        }"
      >
        {{ translate('tabs.images') }}
      </a>
      <a
        :href="'/?action=news&q=' + encodeURIComponent(queryStore.q)"
        :class="{
          active: queryStore.action === 'news',
        }"
      >
        <span>{{ translate('tabs.news') }}</span>
      </a>
      <a
        :href="'/?action=video&q=' + encodeURIComponent(queryStore.q)"
        :class="{
          active: queryStore.action === 'video',
        }"
      >
        {{ translate('tabs.videos') }}
      </a>
      <lilo-dropdown aria-role="list">
        <template #trigger="{ active }">
          <a
            :class="{
              'tabs-not-active-hover': active,
            }"
          >
            {{ translate('tabs.maps') }}
          </a>
        </template>
        <lilo-dropdown-item
          aria-role="listitem"
          :href="
            'https://maps.google.com/?q=' + encodeURIComponent(queryStore.q)
          "
        >
          <span class="icon-text">
            <span class="icon">
              <img
                src="@/assets/images/icons/google-maps.svg"
                alt="maps icon"
              />
            </span>
            <span class="has-text-weight-semibold">Google Maps</span>
          </span>
        </lilo-dropdown-item>
        <lilo-dropdown-item
          aria-role="listitem"
          :href="
            'https://www.bing.com/maps/?q=' + encodeURIComponent(queryStore.q)
          "
        >
          <span class="icon-text">
            <span class="icon">
              <img src="@/assets/images/icons/bing-maps.svg" alt="maps icon" />
            </span>
            <span class="has-text-weight-semibold">Bing</span>
          </span>
        </lilo-dropdown-item>
      </lilo-dropdown>
      <a
        v-if="queryStore.action === 'web' || queryStore.action === 'image'"
        @click="$emit('toggleFilters')"
      >
        {{ translate('tabs.filters') }}
      </a>
      <a
        :href="'https://google.fr/search?q=' + encodeURIComponent(queryStore.q)"
        class="is-pulled-right"
      >
        {{ translate('tabs.google') }}
      </a>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NavBarMobile',

  props: {
    connected: {
      type: Boolean,
      default() {
        return false
      },
    },
    dropsCount: {
      type: Number,
      default() {
        return 0
      },
    },
  },

  emits: ['toggleFilters'],
})
</script>
