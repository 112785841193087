<template>
  <div v-if="showModalAntibot" class="blur">
    <ModalAntibot
      :key="antibotKey"
      :show="showModalAntibot"
      @open-modal-result="openModalResult"
    />
    <ModalResult
      :key="antibotKey"
      :show="showModalResult"
      :success="captchaResult"
      :domain="domain"
      @close-modal-result="showModalResult = false"
      @reopen-modal-antibot="antibotKey += 1"
    />
    <img :src="antibotBackgroundImage" alt="antibot background" />
  </div>

  <ModalTribeWelcome
    v-if="mainStore.displayTribeWelcome && queryStore.userInfo.tribe"
  />

  <Home v-if="displayHome && !showModalAntibot" />

  <Search
    v-else
    :search-response="searchResponse"
    :video-response="videoResponse"
    :news-response="newsResponse"
  />
  <!-- Include scripts -->
</template>

<script lang="ts">
import { BingClient, getAdUnitId } from '~/services/bingClient'
import { YoutubeClient } from '~/services/youtubeClient'
import { WikipediaClient } from '~/services/wikipediaClient'
import { GnewsioClient } from '~/services/gnewsioClient'
import { AntibotClient } from '~/services/antibotClient'
import { getUser } from '~/services/userClient'
import { useMainStore } from '~/store/index'
import { useImageStore } from '~/store/image'
import { useQueryStore } from '~/store/query'
import { Hasher } from '~/services/hasher'

const MARKETS = [
  'en-GB',
  'pt-PT',
  'it-IT',
  'en-US',
  'es-ES',
  'fr-CH',
  'fr-CA',
  'fr-BE',
  'fr-FR',
  'de-DE',
]

const SIZE_FILTERS = ['All', 'Small', 'Medium', 'Large', 'Wallpaper']

const DATE_FILTERS = ['All', 'Day', 'Week', 'Month']

function isRequestLanguageFrench(header: string): boolean {
  const acceptLanguageHeader = header

  if (!acceptLanguageHeader) {
    return false
  }

  const languages = acceptLanguageHeader.split(',')

  for (const language of languages) {
    if (language.trim().startsWith('fr')) {
      return true
    }
  }

  return false
}

export default defineNuxtComponent({
  name: 'IndexPage',

  async asyncData({ ssrContext, vueApp, $pinia, $device }) {
    const route = vueApp.config.globalProperties.$route
    const pinia = $pinia || vueApp.config.globalProperties.$pinia
    const config = useRuntimeConfig()

    const mainStore = useMainStore(pinia)
    const imageStore = useImageStore(pinia)
    const queryStore = useQueryStore(pinia)

    if (process.server) {
      const nuxtApp = useNuxtApp()

      const userInfo = await getUser(route.query.externalsource)
      const hostname = new URL(config.public.domain).hostname

      if (userInfo) {
        queryStore.setUserInfo(userInfo)
        queryStore.headers = ssrContext.event?.req?.headers

        queryStore.$persist()

        nuxtApp.$setLiloCookie(
          'userkey',
          userInfo.userkey,
          config.public.isDev
            ? {}
            : {
                domain: `.${hostname.split('.').slice(-2).join('.')}`,
              },
        )
      }

      if (!userInfo) {
        mainStore.userApiError = true
      }

      if (route.query.lang && ['fr', 'en'].includes(route.query.lang)) {
        nuxtApp.$setLiloCookie(
          'uilng',
          route.query.lang,
          config.public.isDev
            ? {}
            : {
                domain: `.${hostname.split('.').slice(-2).join('.')}`,
              },
        )
      }

      const muid = useCookie('msft_MUID')?.value
      if (muid) {
        queryStore.muid = muid
      }

      const uilng = useCookie('uilng')?.value
      if (uilng && ['fr', 'en'].includes(uilng)) {
        mainStore.locale = uilng
      } else {
        mainStore.locale = isRequestLanguageFrench(
          queryStore.headers['accept-language'],
        )
          ? 'fr'
          : 'en'
      }

      let connectingIp: string | null = null
      if (!config.public.isDev) {
        if (queryStore.headers['cf-connecting-ip']) {
          connectingIp = queryStore.headers['cf-connecting-ip']
        }

        if (connectingIp == null && queryStore.headers['x-real-ip']) {
          const forwardedFor = queryStore.headers['x-real-ip'] as string
          // X-Forwarded-For can contain a comma-separated list of IP addresses
          // The client IP is typically the first one in the list
          connectingIp = forwardedFor.split(',')[0].trim()
        }

        if (connectingIp == null && queryStore.headers['x-forwarded-for']) {
          const forwardedFor = queryStore.headers['x-forwarded-for'] as string
          // X-Forwarded-For can contain a comma-separated list of IP addresses
          // The client IP is typically the first one in the list
          connectingIp = forwardedFor.split(',')[0].trim()
        }
      }

      if (queryStore.headers['cf-ipcountry']) {
        queryStore.baui = getAdUnitId(
          queryStore.headers['cf-ipcountry'],
          $device.isAndroid,
          $device.isIos,
        )
      } else {
        queryStore.baui = '11647068'
      }

      if (config.public.isDev) {
        connectingIp = '176.143.16.239'
      }

      mainStore.connectingIp = connectingIp
      mainStore.userAgent = queryStore.headers['user-agent']
    }

    if (!route.query.q) {
      return {
        displayHome: true,
        showModalAntibot: false,
      }
    }

    // @ts-ignore: parameter should have a value has per validate()
    const query = unescape(route.query.q)

    if (process.server) {
      if (!mainStore.userApiError) {
        const hasher = new Hasher()
        queryStore.secret = hasher.generateHash(
          'toto' + queryStore.userInfo.userkey,
        )
      }

      // Populate store for filters
      // @ts-ignore: parameter might be null
      if (route.query.date && DATE_FILTERS.includes(route.query.date)) {
        queryStore.filterDate = route.query.date
      }

      if (route.query.market && MARKETS.includes(route.query.market)) {
        queryStore.filterMarket = route.query.market
      } else if (queryStore.userInfo.searchPreferences) {
        queryStore.filterMarket = queryStore.userInfo.searchPreferences.market
      }

      // @ts-ignore: parameter might be null
      if (route.query.size && SIZE_FILTERS.includes(route.query.size)) {
        queryStore.filterSize = route.query.sizew
      }

      // @ts-ignore: parameter might be null
      if (route.query.page) {
        const page = Number(route.query.page)
        if (Number.isInteger(page) && page >= 0) {
          queryStore.currentPageNumber = page
        }
      }

      queryStore.q = query
    }

    if (query && !config.public.isDev) {
      const antibotClient = new AntibotClient()
      const data = await antibotClient.checkIp(
        queryStore.userkey,
        mainStore.connectingIp,
        query,
        queryStore.currentPageNumber,
        route,
        $device,
        queryStore.baui,
      )

      // If the  user has been blocked by the antibot system
      if (data) {
        return data
      }
    }

    const data = {
      searchResponse: null,
      videoResponse: null,
      newsResponse: null,
      displayHome: false,
      showModalAntibot: false,
      showModalResult: false,
      ipBlocked: false,
      antibotKey: 0,
      captchaResult: false,
      antibotBackgroundImage: null,
    }

    if (!route.query.action || route.query.action === 'web') {
      const bingClient = new BingClient()
      // @ts-ignore
      data.searchResponse = await bingClient.search(queryStore, mainStore)
      for (let i = 0; i < 3; i++) {
        try {
          // @ts-ignore
          const url = new URL(data.searchResponse.textResults[i].url)
          if (
            url.hostname.includes('wikipedia') &&
            !url.pathname.includes('Portail')
          ) {
            const client = new WikipediaClient()
            // @ts-ignore
            data.searchResponse.wikipediaData = await client.fetch(
              decodeURIComponent(url.pathname.replace('/wiki/', '')),
              queryStore.filterMarket.slice(0, 2),
            )
            break
          }
        } catch (e) {
          // Do nothing (:
        }
      }
    }

    if (route.query.action) {
      queryStore.action = route.query.action
      if (route.query.action === 'video') {
        const youtubeClient = new YoutubeClient()
        // @ts-ignore
        data.videoResponse = await youtubeClient.videos(query)
      }
      if (route.query.action === 'image') {
        const bingClient = new BingClient()
        imageStore.imageResponse = await bingClient.searchImages(
          0,
          queryStore,
          mainStore,
        )
      }
      if (route.query.action === 'news') {
        const gnewsioClient = new GnewsioClient()
        // @ts-ignore
        data.newsResponse = await gnewsioClient.fetchNews(queryStore)
      }
    }

    return structuredClone(data)
  },

  head(nuxtApp) {
    const mainStore = useMainStore(nuxtApp.$pinia)
    const queryStore = useQueryStore(nuxtApp.$pinia)

    let stylesheets = []
    let scripts = []
    if (mainStore.assetsPath) {
      stylesheets = [
        {
          rel: 'stylesheet',
          // @ts-ignore: should have a value has per asyncData()
          href: mainStore.assetsPath + 'nuxtReactNotificationSystemCss',
        },
        {
          rel: 'stylesheet',
          // @ts-ignore: should have a value has per asyncData() prettier-ignore
          href: mainStore.assetsPath + 'nuxtReactNotificationMenuCss',
        },
        {
          rel: 'stylesheet',
          // @ts-ignore: should have a value has per asyncData() prettier-ignore
          href: mainStore.assetsPath + 'nuxtReactDropMenuCss',
        },
        {
          rel: 'stylesheet',
          // @ts-ignore: should have a value has per asyncData() prettier-ignore
          href: mainStore.assetsPath + 'nuxtReactBurgerMenuCss',
        },
      ]

      scripts = [
        {
          src: mainStore.assetsPath + 'nuxtReactDropMenuJs',
          type: 'text/javascript',
          defer: true,
          async: true,
        },
        {
          src: mainStore.assetsPath + 'nuxtReactBurgerMenuJs',
          type: 'text/javascript',
          defer: true,
          async: true,
        },
        {
          src: mainStore.assetsPath + 'nuxtReactNotificationSystemJs',
          type: 'text/javascript',
          defer: true,
          async: true,
        },
        {
          src: mainStore.assetsPath + 'nuxtReactNotificationMenuJs',
          type: 'text/javascript',
          defer: true,
          async: true,
        },
      ]

      if (queryStore.q) {
        scripts.push({
          src: 'https://render.lilo.org/js/telemetry.js',
        })
      }
    }

    return {
      link: stylesheets,
      script: scripts,
      meta: [
        {
          name: 'robots',
          content: 'noindex, nofollow',
        },
      ],
    }
  },

  methods: {
    openModalResult(success: boolean) {
      this.showModalResult = true
      this.captchaResult = success
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/utils/breakpoints.scss';

@media screen and (min-width: $large) {
  .container {
    margin-left: 4%;
    max-width: 92%;
  }
}

@media screen and (min-width: $x-large) {
  .container {
    margin-left: calc(100% / 12);
  }
}

.level {
  img {
    width: 100%;
  }
}

.min-height {
  min-height: 19rem;
}

.blur {
  filter: blur(5px);
}
</style>
