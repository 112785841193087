<template>
  <div class="navbar columns pt-2 is-multiline is-vcentered mb-0">
    <a id="logo" href="https://search.lilo.org" class="column py-0 is-1">
      <img src="@/assets/images/logo.png" alt="Link to search engine home" />
    </a>
    <SearchBar />
    <div
      id="menus"
      class="column pb-0 is-6 is-flex is-flex-direction-row-reverse"
    >
      <div class="is-flex is-align-items-start">
        <a
          v-if="!mainStore.userApiError"
          class="px-2 is-rounded mr-0"
          onclick="document.dispatchEvent(new CustomEvent('DropMenu__Open',{detail:{}}));window.clarity('event', 'click_navbar-drops-button');"
          role="button"
          aria-label="Drops menu toggle"
        >
          <img
            class="icon-text pt-1 mr-1"
            src="@/assets/images/icons/drop-white.svg"
            alt="Drops menu toggle icon"
          />
          <span class="has-text-weight-bold has-text-white is-size-6">{{
            dropsCount
          }}</span>
        </a>
        <div
          v-if="!mainStore.userApiError"
          class="lilo-front-notification-mail-button no_trad"
          title="Notifications"
          role="button"
          aria-label="Notifications menu toggle"
          onclick="window.clarity('event', 'click_navbar-notification-button');"
        ></div>
        <a href="/mon-compte" class="no_trad">
          <span
            class="dot"
            :class="{
              'has-background-secondary': !mainStore.userApiError && connected,
              'has-background-grey-light': !connected,
            }"
          />
          <img
            src="@/assets/images/icons/user.svg"
            width="24"
            alt="Link to my account page"
          />
        </a>
        <a
          class="no_trad"
          onclick="document.dispatchEvent(new CustomEvent('BurgerMenu__Open',{detail:{}}));"
          role="button"
          aria-label="Navigation menu toggle"
        >
          <img
            src="@/assets/images/icons/menu.svg"
            alt="Navigation menu toggle icon"
          />
        </a>
      </div>
    </div>
    <div id="tabs" class="column is-5 is-offset-1 is-8-tablet pb-0 pt-1 mt-3">
      <a
        class="icon-text"
        :href="'/?q=' + encodeURIComponent(queryStore.q)"
        :class="{
          active: queryStore.action === 'web',
        }"
      >
        <span class="icon">
          <img
            src="@/assets/images/icons/search-bordered.svg"
            alt="bordered icon"
          />
        </span>
        <span>{{ translate('tabs.web') }}</span>
      </a>
      <a
        class="icon-text"
        :href="'/?action=image&q=' + encodeURIComponent(queryStore.q)"
        :class="{
          active: queryStore.action === 'image',
        }"
      >
        <span class="icon">
          <img
            src="@/assets/images/icons/images-bordered.svg"
            alt="bordered icon"
          />
        </span>
        <span>{{ translate('tabs.images') }}</span>
      </a>
      <a
        class="icon-text"
        :href="'/?action=news&q=' + encodeURIComponent(queryStore.q)"
        :class="{
          active: queryStore.action === 'news',
        }"
      >
        <span class="icon">
          <img
            src="@/assets/images/icons/news-bordered.svg"
            alt="bordered icon"
          />
        </span>
        <span>{{ translate('tabs.news') }}</span>
      </a>
      <a
        class="icon-text"
        :href="'/?action=video&q=' + encodeURIComponent(queryStore.q)"
        :class="{
          active: queryStore.action === 'video',
        }"
      >
        <span class="icon">
          <img
            src="@/assets/images/icons/videos-bordered.svg"
            alt="bordered icon"
          />
        </span>
        <span>{{ translate('tabs.videos') }}</span>
      </a>
      <lilo-dropdown aria-role="list">
        <template #trigger="{ active }">
          <a
            class="icon-text"
            :class="{
              'tabs-not-active-hover': active,
            }"
            href="#"
          >
            <span class="icon">
              <img
                src="@/assets/images/icons/maps-bordered.svg"
                alt="bordered icon"
              />
            </span>
            <span>{{ translate('tabs.maps') }}</span>
          </a>
        </template>

        <lilo-dropdown-item
          aria-role="listitem"
          :href="
            'https://maps.google.com/?q=' + encodeURIComponent(queryStore.q)
          "
        >
          <span class="icon-text">
            <span class="icon">
              <img
                src="@/assets/images/icons/google-maps.svg"
                alt="maps icon"
              />
            </span>
            <span class="has-text-weight-semibold">Google Maps</span>
          </span>
        </lilo-dropdown-item>
        <lilo-dropdown-item
          aria-role="listitem"
          :href="
            'https://www.bing.com/maps/?q=' + encodeURIComponent(queryStore.q)
          "
        >
          <span class="icon-text">
            <span class="icon">
              <img src="@/assets/images/icons/bing-maps.svg" alt="maps icon" />
            </span>
            <span class="has-text-weight-semibold">Bing</span>
          </span>
        </lilo-dropdown-item>
      </lilo-dropdown>
      <a
        v-if="queryStore.action === 'web' || queryStore.action === 'image'"
        class="icon-text is-inline-flex"
        @click="$emit('toggleFilters')"
      >
        <span>{{ translate('tabs.filters') }}</span>
      </a>
      <a
        class="icon-text is-inline-flex"
        :href="'https://google.fr/search?q=' + queryStore.q"
      >
        <span>{{ translate('tabs.google') }}</span>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import SearchBar from './SearchBar.vue'

export default defineComponent({
  name: 'NavBar',

  components: {
    SearchBar,
  },

  props: {
    connected: {
      type: Boolean,
      default() {
        return false
      },
    },
    dropsCount: {
      type: Number,
      default() {
        return 0
      },
    },
  },

  emits: ['toggleFilters'],
})
</script>
