<template>
  <div id="home-container">
    <div class="modals">
      <Modal
        v-if="retrievedDrops > 0"
        :show="showRetrievedDropsModal"
        classes="lilo-modal"
        mask-class="no-overlay"
        body-classes="pt-0 has-text-centered"
      >
        <template #header>
          <div class="is-flex is-justify-content-space-around py-3">
            <h3 class="is-size-5 has-text-primary has-text-weight-semibold">
              {{ translate('modal.message.retrieved_drops.title') }} 🎉
            </h3>
          </div>
          <img
            class="close"
            src="@/assets/images/icons/cross-dark.svg"
            width="12"
            alt="plus"
            @click="hideRetrievedDropsModal"
          />
        </template>
        <template #body>
          <h4 class="has-text-primary-dark has-text-weight-semibold">
            <span v-if="retrievedDrops < 2">{{
              translate('modal.message.retrieved_drops.message_single_drop')
            }}</span>
            <span v-if="retrievedDrops > 1"
              >{{
                translate(
                  'modal.message.retrieved_drops.message_multiple_drops_1',
                )
              }}
              {{ retrievedDrops }}
              {{
                translate(
                  'modal.message.retrieved_drops.message_multiple_drops_2',
                )
              }}</span
            >
          </h4>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-center">
            <button
              class="lilo-button-primary"
              @click="hideRetrievedDropsModal"
            >
              {{ translate('cta.close') }}
            </button>
          </div>
        </template>
      </Modal>
    </div>
    <div id="home-content">
      <div
        v-show="!searchBarFocus"
        class="navbar is-fixed-top-desktop"
        :class="{
          'pt-3': !hasScrolled,
          'has-shadow': hasScrolled,
        }"
      >
        <div v-if="!hasScrolled" class="navbar-start pl-3">
          <a
            href="https://lilo.org/projets"
            target="_blank"
            class="button is-rounded"
            :class="{
              'home-tour-step-3 has-background-grey-light is-size-7 pl-0':
                !device.isDesktop,
              'lilo-button-secondary projects-link pl-1 has-font-family-secondary':
                device.isDesktop,
            }"
          >
            <img
              v-if="device.isDesktop"
              class="icon-text"
              src="@/assets/images/icons/white-heart.svg"
            />
            <img
              v-if="!device.isDesktop"
              class="icon-text"
              src="@/assets/images/icons/green-heart.svg"
            />
            <span
              class="ml-2 has-text-weight-bold"
              :class="{
                'has-text-secondary': !device.isDesktop,
              }"
              >{{
                translate(
                  device.isDesktop
                    ? 'home.cta.support_a_project'
                    : 'home.cta.projects',
                )
              }}</span
            >
          </a>
        </div>
        <img
          v-if="hasScrolled"
          class="ml-5"
          src="@/assets/images/logo.png"
          alt="Lilo moteur de recherche solidaire"
          width="80"
        />
        <SearchBar v-if="hasScrolled" class="pt-3" />
        <div
          id="menus"
          class="navbar-end pb-0"
          :class="{
            'pt-1': !device.isDesktop,
          }"
        >
          <div class="is-flex is-align-items-start">
            <a
              class="px-2 is-rounded mr-0 home-tour-step-1"
              onclick="document.dispatchEvent(new CustomEvent('DropMenu__Open',{detail:{}})); window.clarity('event', 'click_navbar-drops-button');"
              role="button"
              aria-label="Drops menu toggle"
            >
              <img
                class="icon-text pt-1"
                src="@/assets/images/icons/drop-white.svg"
                alt="Drops menu toggle icon"
              />
              <span class="has-text-weight-bold has-text-white is-size-6 ml-1">
                {{ queryStore.userInfo.dropsCount }}
              </span>
            </a>
            <div
              class="lilo-front-notification-mail-button no_trad"
              title="Notifications"
              role="button"
              aria-label="Notifications menu toggle"
              onclick="window.clarity('event', 'click_navbar-notifications-button')"
            ></div>
            <a href="/mon-compte" class="no_trad home-tour-step-2">
              <span
                class="dot"
                :class="{
                  'has-background-secondary': queryStore.userInfo.connected,
                  'has-background-grey-light': !queryStore.userInfo.connected,
                }"
              />
              <img
                src="@/assets/images/icons/user.svg"
                width="24"
                alt="Link to my account page"
              />
            </a>
            <a
              class="no_trad"
              onclick="document.dispatchEvent(new CustomEvent('BurgerMenu__Open',{detail:{}}));"
              role="button"
              aria-label="Navigation menu toggle"
            >
              <img
                src="@/assets/images/icons/menu.svg"
                alt="Navigation menu toggle icon"
              />
            </a>
          </div>
        </div>
        <ReactApps />
      </div>

      <section
        class="home"
        :class="{
          'pt-5': queryStore.userInfo?.homePreferences?.news_feed,
        }"
        :style="{
          'margin-top': device.isDesktop
            ? !queryStore.userInfo?.homePreferences?.bookmarks
              ? '15vh'
              : '20vh'
            : searchBarFocus
              ? '0vh'
              : '5vh',
        }"
      >
        <img
          v-show="!searchBarFocus"
          class="home__logo"
          src="@/assets/images/logo-lilo-octobre-rose.gif"
          alt="Lilo moteur de recherche solidaire X Octobre Rose"
        />
        <SearchBar
          :large="true"
          :autofocus="true"
          :style="{
            visibility: !device.isDesktop
              ? 'visible'
              : hasScrolled
                ? 'hidden'
                : 'visible',
          }"
          :source="'lilo_home_n'"
          @search-bar-focus="handleSearchBarFocus"
          @search-bar-blur="handleSearchBarBlur"
        />
        <p
          v-show="!searchBarFocus"
          class="home__collected is-size-6 is-size-7-touch mx-3"
          :style="{
            'margin-bottom':
              !queryStore.userInfo?.homePreferences?.bookmarks &&
              !device.isDesktop
                ? '10rem'
                : '0',
          }"
        ></p>
        <div
          class="is-flex"
          :class="{
            'is-flex-direction-column':
              device.isDesktop &&
              queryStore.userInfo?.homePreferences?.bookmarks,
            'is-flex-direction-column-reverse':
              device.isDesktop &&
              !queryStore.userInfo?.homePreferences?.bookmarks,
          }"
        >
          <div>
            <div
              v-show="!searchBarFocus"
              key="home__bookmarks-container"
              class="home__bookmarks-container"
              :class="{
                'my-5': device.isDesktop && bookmarks.length > 7,
              }"
            >
              <!--            <Sortable
                  v-if="!device.isIos"
                  :list="bookmarks"
                  item-key="id"
                  class="home__bookmarks"
                  :options="sortableOptions"
                  @end="storeNewOrder"
                >
                  <template #item="{ element }">
                    <Bookmark
                      :id="element.id"
                      :title="element.title"
                      :url="element.url"
                      :icon="element.icon"
                      @update-bookmarks="getBookmarksFromLocalStorage"
                    />
                  </template>
                </Sortable>
                <div v-if="device.isIos" class="home__bookmarks">-->
              <div
                v-if="queryStore.userInfo?.homePreferences?.bookmarks"
                class="home__bookmarks"
              >
                <Bookmark
                  v-for="(element, bookmarkIndex) in bookmarks"
                  :id="element.id"
                  :key="'home-bookmark-' + bookmarkIndex"
                  :title="element.title"
                  :url="element.url"
                  :icon="element.icon"
                  @update-bookmarks="getBookmarksFromLocalStorage"
                />
              </div>
              <!--            </div>-->
              <div
                v-if="bookmarks?.length === 1"
                class="home__bookmarks__empty ml-3"
              >
                {{ translate('bookmarks.empty') }}
              </div>
            </div>
          </div>
          <div
            v-if="device.isDesktop && mainStore.locale === 'fr'"
            class="is-align-self-center"
            :class="{
              'mt-5':
                device.isDesktop &&
                !queryStore.userInfo?.homePreferences?.bookmarks,
            }"
          >
            <SponsoredBookmarksRow />
          </div>
        </div>
        <!--        </ClientOnly>-->
        <div
          v-if="queryStore.userInfo?.homePreferences?.news_feed"
          v-show="!searchBarFocus"
          class="home__newsfeed-container"
          :class="{
            'mt-6': device.isDesktop,
          }"
        >
          <Feed />
        </div>
      </section>
    </div>
    <LiloFooter v-show="!searchBarFocus" />
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'
// import { Sortable } from 'sortablejs-vue3'
// eslint-disable-next-line import/named
import { useShepherd } from 'vue-shepherd'
import { offset } from '@floating-ui/dom'
import 'shepherd.js/dist/css/shepherd.css'
import Bookmark from './bookmarks/Bookmark.vue'
import Feed from './newsfeed/Feed.vue'
import SponsoredBookmarksRow from './bookmarks/SponsoredBookmarksRow.vue'
import { getBookmarks } from '~/services/bookmarkClient'

/* const sortableOptions = {
  filter: '.bookmark__add',
  swapThreshold: '0.2',
  animation: '400',
  ghostClass: 'sortable-ghost',
  delay: 200,
  delayOnTouchOnly: true,
  draggable: '.draggable', // Specifies which items inside the element should be draggable
} */
const hasScrolled = ref(false)
const searchBarFocus = ref(false)
const retrievedDrops = ref(0)
const showRetrievedDropsModal = ref(false)
const localStorageAccess = ref(true)

const { data: bookmarks } = await useAsyncData('bookmarks', () =>
  getBookmarks(),
)

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
  localStorageAccess.value = isLocalStorageAccessible()
  const urlParams = new URLSearchParams(window.location.search)

  if (localStorageAccess.value) {
    localStorage.removeItem('bookmarks')
    localStorage.setItem('bookmarks', JSON.stringify(bookmarks.value))
  }

  /* if (bookmarksListHasError()) {
  } */

  const nbDrops = urlParams.get('nb_drops')
  if (urlParams.get('message') === 'retrievedDrops' && nbDrops) {
    retrievedDrops.value = nbDrops
    showRetrievedDropsModal.value = true
  }

  const device = useDevice()
  if (!device.isDesktop) {
    const bookmarksMaxHeight = window.innerHeight / 10
    const bookmarks = document.querySelectorAll(
      '.bookmark, .home__bookmarks__empty',
    )
    bookmarks.forEach((element) => {
      element.style.maxHeight = 1.5 * bookmarksMaxHeight + 'px'
    })

    const bookmarksContainer = document.querySelector(
      '.home__bookmarks-container',
    )
    if (bookmarksContainer) {
      bookmarksContainer.style.minHeight = 2 * bookmarksMaxHeight + 'px'
    }

    handleMobileActions(urlParams)
  }

  const { $tag } = useNuxtApp()
  $tag('open_nuxt-homepage', {})
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

const hideRetrievedDropsModal = () => {
  showRetrievedDropsModal.value = false
}

const handleMobileActions = (urlParams: URLSearchParams) => {
  if (urlParams.get('ma') === 'login') {
    const config = useRuntimeConfig()
    const queryStore = useQueryStore()
    window.location.replace(
      config.public.accountDomain +
        '/login-check/' +
        queryStore.userInfo.userkey,
    )
  }

  if (urlParams.get('act') === 'introduction') {
    startHomeTour()
  }
}

const startHomeTour = () => {
  const tour = useShepherd({
    useModalOverlay: true,
    defaultStepOptions: {
      classes: 'lilo-shepherd-theme',
      cancelIcon: {
        enabled: true,
      },
      floatingUIOptions: {
        middleware: [offset({ mainAxis: 12, crossAxis: 0 })],
      },
    },
  })

  const { $translate } = useNuxtApp()
  const tourNavigationButtons = [
    {
      text: $translate('cta.back'),
      action: tour.back,
    },
    {
      text: $translate('cta.next'),
      action: tour.next,
    },
  ]

  tour.addSteps([
    {
      title: $translate('home.tour.step_0.title'),
      text: $translate('home.tour.step_0.content'),
      buttons: [
        {
          text: $translate('cta.next'),
          action: tour.next,
        },
      ],
    },
    {
      attachTo: {
        element: '.home-tour-step-1',
        on: 'bottom',
      },
      text: $translate('home.tour.step_1.content'),
      classes: 'has-margin-from-right-border',
      buttons: tourNavigationButtons,
    },
    {
      attachTo: {
        element: '.home-tour-step-2',
        on: 'bottom',
      },
      text: $translate('home.tour.step_2.content'),
      buttons: tourNavigationButtons,
      modalOverlayOpeningXOffset: 7,
    },
    {
      attachTo: {
        element: '.home-tour-step-3',
        on: 'bottom',
      },
      text: $translate('home.tour.step_3.content'),
      buttons: tourNavigationButtons,
    },
    {
      text: $translate('home.tour.step_4.content'),
      buttons: [
        {
          text: $translate('cta.end'),
          action: tour.complete,
        },
      ],
    },
  ])

  tour.start()
}

const handleSearchBarFocus = () => {
  const device = useDevice()
  if (!device.isDesktop) {
    searchBarFocus.value = true
  }
}

const handleSearchBarBlur = () => {
  searchBarFocus.value = false
}

const handleScroll = () => {
  hasScrolled.value = window.scrollY > 190
}

function isLocalStorageAccessible() {
  try {
    // Try to set an item in localStorage
    localStorage.setItem('test', 'testValue')

    // Try to retrieve the item
    const test = localStorage.getItem('test')

    // Clean up by removing the test item
    localStorage.removeItem('test')

    // Check if the item was stored and retrieved successfully
    return test === 'testValue'
  } catch (e) {
    // If an error occurs, localStorage is not accessible
    return false
  }
}

function getBookmarksFromLocalStorage() {
  try {
    const data = JSON.parse(localStorage.getItem('bookmarks'))
    if (Array.isArray(data)) {
      bookmarks.value = data.filter(
        (obj, index, self) => self.findIndex((o) => o.id === obj.id) === index,
      )
    }
  } catch (e) {
    console.log('Failed to load local bookmarks')
    localStorage.removeItem('bookmarks')
  }
}

/* function bookmarksListHasError() {
  if (bookmarks.value?.length < 1) {
    return true
  }

  const error = false
  for (const bookmark of bookmarks.value) {
    if (!bookmark?.url?.length < 1 || !bookmark?.title?.length < 1) {
      return true
    }
    if (bookmark?.id === 'addBookmarkPlaceholder') {
      return true
    }
  }

  return error
} */

/* function storeNewOrder(evt: { oldIndex: any; newIndex: any }) {
  bookmarks.value = moveElement(bookmarks.value, evt.oldIndex, evt.newIndex)
  if (localStorageAccess.value) {
    localStorage.setItem('bookmarks', JSON.stringify(bookmarks.value))
  }
} */

/* function moveElement(arr: any[], fromIndex: number, toIndex: number) {
  const element = arr.splice(fromIndex, 1)[0]
  arr.splice(toIndex, 0, element)
  return arr
} */
</script>

<style lang="scss">
@import '~/assets/scss/utils/variables.scss';
@import '~/assets/scss/utils/breakpoints.scss';

#home-container {
  position: relative;
  min-height: 100vh;

  #home-content {
    display: grid;
    min-height: 80vh;
    align-items: flex-start;
  }

  footer {
    @media screen and (min-width: $large) {
      position: absolute;
      bottom: 0;
    }
    width: 100%;
  }

  .navbar {
    align-content: center;
    flex-wrap: wrap;
    border-bottom: none;

    @media screen and (max-width: $large - 1) {
      display: flex;
    }

    .home-tour-step-3 {
      border: none;
    }

    .projects-link {
      font-size: 14px;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

      img {
        height: 1.5rem;
      }
    }

    .is-fixed {
      position: fixed;
    }
  }

  .is-fixed-top-desktop {
    align-items: center;
  }

  .home {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &__newsfeed-container {
      width: 100%;
      margin-bottom: 10rem;
    }

    &__logo {
      width: 130px;
    }

    @media screen and (min-width: $large) {
      gap: 0px;
      &__newsfeed-container {
        width: 50%;
      }

      &__logo {
        width: 160px;
      }
    }

    &__collected {
      text-align: center;
      color: $primary;
    }

    &__bookmarks-container {
      display: flex;
      flex-direction: row;
      min-height: 20vh;

      @media screen and (min-width: $large) {
        max-width: 55vw;
        min-width: 50vw;
        justify-content: center;
        align-items: center;
      }

      @media screen and (max-width: $large - 1) {
        margin-top: 1rem;
      }
    }

    &__bookmarks-container,
    #sponsoredLinksRow {
      .bookmark,
      .sponspored_bookmark {
        border: 1px solid $white;
        padding: 8px;
        background: $white;
        border-radius: 16px;
        position: relative;
        /*cursor: grab; */

        @media screen and (max-width: $large - 1) {
          border: 1px solid $grey-light;
          background: $grey-light;
        }

        &__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
        }

        &__menu-icon {
          position: absolute;
          right: 2px;
          width: 24px;
          cursor: pointer;
          visibility: hidden;

          @media screen and (min-width: $small) {
            width: 12px;
          }

          @media screen and (max-width: $large - 1) {
            right: 0;
            width: 20px;
            visibility: visible;
          }
        }

        &__icon {
          width: 48px;
          height: 48px;
          border-radius: 16px;
          padding: 4px;
          box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
          background-color: white;
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          justify-content: space-around;
        }

        &__title {
          width: 70px;
          font-size: 12px;
          word-break: break-all;
          text-align: center;
          user-select: none;
        }

        &__icon__add {
          width: 48px;
          height: 48px;
          box-shadow: none;
          background-color: transparent;
          padding: 0;
        }

        &__add {
          cursor: pointer;

          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @media screen and (min-width: $large) {
            max-height: 135px;
          }
        }
      }

      .bookmark:hover,
      .sponspored_bookmark:hover {
        background-color: $grey-lighter;
        transition: background-color 250ms linear;

        .bookmark__menu-icon {
          visibility: visible;
        }
      }
    }

    &__bookmarks {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @media screen and (min-width: $large) {
        max-width: 90%;
      }

      @media screen and (max-width: $large - 1) {
        gap: 10px;
      }

      &__empty {
        border: 1px solid $grey-light;
        padding: 20px 30px;
        align-content: center;
        background: $grey-light;
        border-radius: 16px;
        word-break: auto-phrase;
        width: 250px;
        color: $grey-dark;
        font-size: 14px;
        @media screen and (min-width: $large) {
          max-height: 135px;
        }
        @media screen and (max-width: $large - 1) {
          padding: 0px 30px;
        }
      }
    }

    .sortable-ghost {
      opacity: 0.5;
      border: 4px dashed $grey;
    }
  }
}
</style>
