<template>
  <div class="sponspored_bookmark">
    <div class="bookmark__content">
      <a
        :href="url"
        class="bookmark__icon"
        target="_blank"
        :class="{
          'has-placeholder': !icon || isIconOnError,
        }"
      >
        <NuxtImg
          :src="icon ? icon : '/placeholder-1by1.svg'"
          placeholder="/placeholder-1by1.svg"
          alt="icon"
          preload
          densities="x1 x2"
          @load="onIconLoad"
          @error="onIconError"
        />
      </a>
      <a class="bookmark__title is-one-line has-text-grey-dark">
        {{ translate(title) || title }}
      </a>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

defineProps({
  id: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  url: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
})

const isIconOnError = ref(true)

const onIconError = () => {
  isIconOnError.value = true
}

const onIconLoad = () => {
  isIconOnError.value = false
}
</script>
