<template>
  <div
    class="search-bar column pb-0"
    :class="{
      'is-5': !large,
      'pt-2': !large && device.isDesktop,
      'pt-0': !large && !device.isDesktop,
      'is-6': large,
    }"
  >
    <form
      id="searchBar"
      class="field"
      :class="{
        'mb-3': !large,
      }"
      @submit="onSubmit"
    >
      <input class="is-hidden" name="action" :value="queryStore.action" />
      <p
        ref="searchBox"
        class="control"
        :class="{
          large: large,
        }"
      >
        <input
          v-model="q"
          autocomplete="off"
          class="input is-rounded"
          type="text"
          name="q"
          :placeholder="
            device.isMobile
              ? translate('search_bar.placeholder_mobile')
              : translate('search_bar.placeholder')
          "
          :class="{
            'is-size-6': !device.isDesktop,
          }"
          :autofocus="autofocus"
          @focus="toggleFocus"
          @blur="toggleFocus"
          @keydown.down="onArrow"
          @keydown.up="onArrow"
        />
        <span class="buttons">
          <button
            type="reset"
            :class="{
              'is-invisible': !isResetVisible,
            }"
            @click="clearSearch"
            @touchend="clearSearch"
          >
            <img
              src="@/assets/images/icons/cross.svg"
              alt="cross icon"
              width="16"
            />
          </button>
          <button type="submit" @click="onSubmit">
            <img
              src="@/assets/images/icons/search.svg"
              alt="search icon"
              width="16"
            />
          </button>
        </span>
      </p>
    </form>
    <div
      v-if="displaySuggestions && queryStore.suggestions"
      :class="{
        'search-menu': device.isDesktop,
        'search-menu-touch': !device.isDesktop,
      }"
    >
      <div
        class="dropdown-content is-font-family-secondary"
        :style="searchBarStyle"
      >
        <div
          v-if="queryStore.suggestTravelAd"
          class="dropdown-item"
          @click="redirectToBooking"
        >
          <span class="icon-text">
            <span class="icon">
              <img
                src="@/assets/images/icons/partners/bookingcom.svg"
                alt="bookingcom icon"
              />
            </span>
            <span v-if="device.isDesktop" class="has-text-weight-semibold"
              >Gagnez des gouttes en réservant sur Booking.com</span
            >
            <span v-if="!device.isDesktop" class="has-text-weight-semibold"
              >Gagnez des gouttes sur Booking.com</span
            >
            <span
              class="has-text-weight-light"
              :class="{
                'ml-3 is-size-7': device.isDesktop,
                'ml-1 is-very-small': !device.isDesktop,
              }"
              >Annonce</span
            >
          </span>
        </div>
        <div
          v-for="(item, index) in queryStore.suggestions"
          :key="'search-suggestion-' + index"
          class="dropdown-item"
          :class="{
            hovered: activeSuggestionItem === index,
          }"
          @click="onSuggestionsClick"
        >
          <span class="icon-text">
            <span class="icon">
              <img
                src="@/assets/images/icons/search-grey.svg"
                alt="grey icon"
              />
            </span>
            <span v-if="item.includes(q)" class="has-text-weight-medium">
              {{ q
              }}<span class="has-text-weight-semibold">{{
                trimSuggestion(item)
              }}</span>
            </span>
            <span v-else class="has-text-weight-semibold">{{ item }}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { BingClient } from '~/services/bingClient'
import { useQueryStore } from '~/store/query'

/* const travelWords = [
  'voyage',
  'hôtel',
  'hotel',
  'vol',
  'destination',
  'vacances',
  'aéroport',
  'aeroport',
  'excursion',
  'tourisme',
  'plage',
  'montagne',
  'train',
  'avion',
  'croisière',
  'réservation',
  'itinéraire',
  'bagages',
  'compagnie aérienne',
  "carte d'embarquement",
  'passeport',
  'frontière',
  'visa',
  'guide touristique',
  'séjour',
  'auberge',
  'station balnéaire',
  'randonnée',
  'étranger',
  'découverte',
  'exploration',
  'location de voiture',
  "chambre d'hôtel",
  'check-in',
  'check-out',
  'séjourner',
  'visiter',
  'départ',
  'arrivée',
  'Expedia',
  'HotelsCombined',
  'Agoda',
  'Kayak',
  'Trivago',
  'Airbnb',
  'TripAdvisor',
  'Orbitz',
  'Priceline',
  'Hotwire',
  'HomeAway',
  'Vrbo',
  'Hostelworld',
  'Travelocity',
  'Wotif',
  'CheapTickets',
  'Lastminute',
  'Ebookers',
  'Opodo',
  'Roomer',
]

function containsAnyWord(array, words) {
  for (let i = 0; i < array.length; i++) {
    for (let j = 0; j < words.length; j++) {
      if (array[i].includes(words[j])) {
        return true
      }
    }
  }
  return false
} */

export default defineComponent({
  name: 'SearchBar',

  props: {
    large: Boolean,
    source: {
      type: String,
      default() {
        return null
      },
    },
    autofocus: {
      type: Boolean,
      default() {
        return false
      },
    },
  },

  emits: ['searchBarFocus', 'searchBarBlur'],

  data() {
    const queryStore = useQueryStore()
    return {
      bingClient: new BingClient(),
      displaySuggestions: false,
      isRedirecting: false,
      q: queryStore.q,
      timer: null,
      activeSuggestionItem: -1,
      hideClearButton: false,
      searchBarWidth: null,
    }
  },

  computed: {
    isResetVisible(): Boolean {
      return Boolean(this.q && this.q.length)
    },
    searchBarStyle() {
      return {
        width: this.searchBarWidth + 'px',
      }
    },
  },

  watch: {
    q: function (newVal: string): void {
      if (this.searchBarWidth === null) {
        this.searchBarWidth = this.$refs.searchBox.clientWidth
      }

      if (newVal) {
        if (newVal.length < 2) {
          this.displaySuggestions = false
        }

        if (!newVal.includes(this.q)) {
          this.queryStore.resetSuggestions()
        }

        if (newVal.length >= 2 && !this.isRedirecting) {
          // @ts-expect-error
          clearTimeout(this.timer)

          // @ts-expect-error
          this.timer = setTimeout(async () => {
            const suggestions = await this.bingClient.fetchSuggestions(
              this.queryStore.filterMarket.substr(0, 2),
              newVal,
            )

            if (suggestions?.length) {
              /* this.queryStore.setSuggestTravelAd(
                containsAnyWord(suggestions, travelWords),
              ) */
              this.queryStore.suggestions = suggestions
              this.displaySuggestions = true
            } else {
              this.queryStore.resetSuggestions()
            }
          }, 250)
        }
      }
    },
  },

  methods: {
    clearSearch(event: Event): void {
      event.preventDefault()
      this.q = ''
      this.hideClearButton = true
    },
    toggleFocus(event: FocusEvent): void {
      if (event.type === 'focus') {
        this.$emit('searchBarFocus')
      }

      setTimeout(() => {
        if (event.type === 'blur') {
          this.$emit('searchBarBlur')
          this.displaySuggestions = false
        }

        if (event.type === 'focus') {
          this.displaySuggestions = true
        }
      }, 300)
    },
    trimSuggestion(suggestion: string): string {
      return suggestion.replace(this.q.toLowerCase(), '')
    },
    updateQuery(newQuery: string): void {
      this.queryStore.resetSuggestions()
      this.isRedirecting = true
      this.q = newQuery

      const url = new URL(window.location.toString())
      url.searchParams.set('q', newQuery)

      if (this.source) {
        url.searchParams.set('source', this.source)
      }

      if (window.self !== window.top) {
        // Inside an iframe, redirect the parent window
        window.parent.location.replace(url.toString())
      } else {
        // Not inside an iframe, redirect the current window
        window.location.replace(url.toString())
      }
    },
    onArrow(event: KeyboardEvent): Number | Boolean {
      if (this.displaySuggestions && this.queryStore.suggestions) {
        const max = this.queryStore.suggestions.length
        return event.code === 'ArrowDown'
          ? this.activeSuggestionItem < max && this.activeSuggestionItem++
          : this.activeSuggestionItem > -1 && this.activeSuggestionItem--
      }
      return -1
    },
    onSuggestionsClick(event: Event): void {
      const input = event.target as HTMLElement
      this.updateQuery(input.innerText)
    },
    onSubmit(event: Event): void {
      event.preventDefault()
      let searchTerm = this.q
      if (this.queryStore.suggestions && this.activeSuggestionItem > -1) {
        searchTerm = this.queryStore.suggestions[this.activeSuggestionItem]
      }

      this.updateQuery(searchTerm)
    },
    async redirectToBooking(): void {
      const apiResponse = await fetch(
        'https://ws.lilo.org/shopping/offer/f3f3f86d-0a59-4f92-bd22-5d8cf415ec94/link',
        {
          method: 'POST',
          headers: {
            'Lilo-Userkey':
              undefined === this.queryStore.userkey
                ? 'fd69e959e8c6d6e70f13633f30908c5b'
                : this.queryStore.userkey,
          },
          body: JSON.stringify({
            url: 'https://www.booking.com/index.fr.html',
          }),
        },
      )

      if (apiResponse.ok) {
        const data = await apiResponse.json()
        window.top.location.href = data.affiliation_link
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~/assets/scss/main.scss';

.search-bar {
  width: 100%;
}

.control {
  input {
    padding-right: 4.5rem !important;
  }

  span.buttons {
    position: absolute;
    right: 0.5rem;
    top: 0.35rem;
    @media screen and (max-width: $large) {
      top: 0.25rem;
    }
    z-index: 2;
    button {
      border-radius: 8rem;
      padding: 6px;
      background: none;
      border: none;
      @media screen and (min-width: $large) {
        &:hover {
          background: $light-blue;
          transition: background-color 500ms linear;
        }
      }
    }
  }
}

.large {
  input {
    height: 3rem;
  }

  .buttons {
    right: 1rem !important;
    top: 0.65rem !important;
  }
}

.search-menu,
.search-menu-touch {
  z-index: 2;
}

.dropdown-item {
  &.hovered,
  &:hover {
    background-color: $light-blue;
  }
}

.icon,
.icon img {
  pointer-events: none;
}

.is-invisible {
  display: none;
}

.is-very-small {
  font-size: 0.5rem !important;
}

.input.is-rounded {
  border: 1.5px solid lighten($primary, 10%);
}
</style>
