<template>
  <div id="lilo-news-feed" class="columns is-multiline p-3">
    <div
      class="column is-full is-flex is-justify-content-space-between is-align-items-center mb-4"
    >
      <h3
        class="is-size-4 is-size-5-touch has-text-primary has-text-weight-semibold icon-text"
      >
        <span class="icon mr-2">
          <img
            src="@/assets/images/icons/icon-arrow-green.png"
            :width="device.isDesktop ? 64 : 36"
            alt="arrow down"
          />
        </span>
        {{ translate('home.news_feed.title') }}
      </h3>
      <button class="lilo-button-grey" @click="openConfigure">
        <Icon
          name="mdi:cog-outline"
          :class="{
            'mr-1': device.isDesktop,
          }"
        />
        <span v-if="device.isDesktop">{{
          translate('home.news_feed.configure')
        }}</span>
      </button>
    </div>
    <ClientOnly>
      <template #fallback>
        <div class="columns is-multiline" style="width: 100%">
          <NewsSkeleton />
          <NewsSkeleton />
          <NewsSkeleton />
          <NewsSkeleton />
        </div>
      </template>
      <div
        v-for="(item, index) in news"
        :key="'news-feed-' + index"
        class="column is-full p-1"
      >
        <NewsResult
          :news-data="item"
          :report-enabled="true"
          class="card"
          @open-report-modal="handleOpenReportModal"
        />
      </div>
      <Configure
        v-if="showConfigureModal"
        @close-news-feed-configure-modal="handleCloseConfigureModal"
      />
      <ReportModal
        v-if="showReportModal"
        :media-name="mediaName"
        :article-name="articleName"
        @close-news-feed-report-modal="handleCloseReportModal"
      />
      <div
        v-if="news?.length > 0"
        class="column is-full is-flex is-justify-content-center mt-4 mb-6"
      >
        <button class="lilo-button-primary-outline" @click="loadMoreNews">
          {{ translate('home.news_feed.see_more') }}
        </button>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, onUnmounted, ref } from 'vue'
import Configure from './Configure.vue'
import ReportModal from './ReportModal.vue'
import NewsSkeleton from './NewsSkeleton.vue'
import { getNews } from '~/services/newsClient'

const news = ref([])
const showConfigureModal = ref(false)
const showReportModal = ref(false)
const articleName = ref('')
const mediaName = ref('')
const page = ref(1)

onBeforeMount(async () => {
  news.value = await getNews()
})

const openConfigure = () => {
  const { $tag } = useNuxtApp()
  $tag('click_configure-newsfeed', {})
  showConfigureModal.value = true
}

const handleOpenReportModal = (media, article) => {
  articleName.value = article
  mediaName.value = media
  showReportModal.value = true
}

const handleCloseReportModal = (openConfigureModal) => {
  showReportModal.value = false
  if (openConfigureModal === true) {
    openConfigure()
  }
}

async function handleCloseConfigureModal(refreshFeed) {
  showConfigureModal.value = false
  if (refreshFeed) {
    news.value = null
    news.value = await getNews()
  }
}

async function loadMoreNews() {
  page.value++
  const additionalNews = await getNews(page.value)
  news.value.push(...additionalNews)
}

onMounted(() => {
  const body = document.querySelector('body')
  body?.addEventListener('click', clickOutsideFeedModals)
})

onUnmounted(() => {
  const body = document.querySelector('body')
  body?.removeEventListener('click', clickOutsideFeedModals)
})

function clickOutsideFeedModals(event: {
  target: { parentNode: { classList: { contains: (arg0: string) => boolean } } }
}) {
  if (showConfigureModal.value === true) {
    showConfigureModal.value =
      !event?.srcElement?.classList?.contains('modal-mask')
  }

  if (showReportModal.value === true) {
    showReportModal.value =
      !event?.srcElement?.classList?.contains('modal-mask')
  }
}
</script>

<style scoped lang="scss">
.columns {
  h3 {
    span {
      width: 36px;
      img {
        height: 36px;
      }
    }
  }
}
</style>
