<template>
  <div id="sponsoredLinksRow" class="is-flex is-flex-direction-column">
    <div
      class="has-border-grey-lighter is-rounded is-inline-flex is-flex-direction-row p-3"
    >
      <SponsoredBookmark
        v-for="(element, bookmarkIndex) in bookmarksData"
        :id="element.id"
        :key="'home-bookmark-' + bookmarkIndex"
        :title="element.title"
        :url="element.url"
        :icon="element.icon"
      />
    </div>
    <p
      class="is-size-7 is-inline-flex is-align-self-flex-end has-text-grey mr-4 mt-1"
    >
      <span>Liens sponsorisés</span>
      <span>
        <VTooltip placement="right" distance="10">
          <span class="ml-2">ⓘ</span>
          <template #popper>
            {{ translate('home.sponsored_links.why') }}
          </template>
        </VTooltip>
      </span>
    </p>
  </div>
</template>

<script setup lang="ts">
import SponsoredBookmark from './SponsoredBookmark.vue'

const bookmarksData = [
  {
    id: '3d89d972-1c1e-4fcd-8d57-044e2b7605ed',
    title: 'Jardins animés',
    url: 'https://fva.jardins-animes.com/?P510963568E87161&redir=https%3A%2F%2Ffr.jardins-animes.com%2F',
    icon: 'https://ws.lilo.org/uploads/favicons/edfcac948e23094ef2e1e0c3d859352e.ico',
  },
  {
    id: '43dd62a3-2021-4f5e-a4f9-7a2ec8d7c25e',
    title: 'Courses U',
    url: 'https://www.awin1.com/cread.php?awinmid=13594&awinaffid=297829',
    icon: 'https://ws.lilo.org/uploads/favicons/51b16ef06020f04e9190e9f2284396d7.ico',
  },
  {
    id: '49086784-cf0c-4f06-a5e1-0f7e9a83ca70',
    title: 'Zooplus',
    url: 'https://www.awin1.com/cread.php?awinmid=7334&awinaffid=297829',
    icon: 'https://ws.lilo.org/uploads/favicons/1bab40ca0c8e59d7f6a3a5e3f59f8d6d.ico',
  },
  {
    id: '52a8b96a-a47b-4fb1-81fc-6b1511574de6',
    title: 'Léa Nature',
    url: 'https://ryt.leanatureboutique.com/?P5127D7568E872131&redir=https%3A%2F%2Fleanatureboutique.com',
    icon: 'https://ws.lilo.org/uploads/favicons/9bd0b6e59d6254f30b23b3898edf86e9.ico',
  },
]
</script>

<style scoped lang="scss">
#sponsoredLinksRow {
  width: max-content;

  .has-border-grey-lighter {
    padding: 2rem;
  }
}
</style>
