/* eslint-disable prefer-rest-params */
export default defineNuxtPlugin(($nuxtApp) => {
  // don't load on server
  if (!process.client) {
    return
  }

  ;(function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        ;(c[a].q = c[a].q || []).push(arguments)
      }
    t = l.createElement(r)
    t.async = 1
    t.src = 'https://www.clarity.ms/tag/' + i
    y = l.getElementsByTagName(r)[0]
    y.parentNode.insertBefore(t, y)
  })(window, document, 'clarity', 'script', 'e0wisq2o8c')

  window.didomiOnReady = window.didomiOnReady || []
  window.didomiOnReady.push(function (Didomi) {
    if (Didomi.isConsentRequired()) {
      // Consent is required: your visitor is from the EU or you are an EU company
      // Only enable the vendor when consent is given
      Didomi.getObservableOnUserConsentStatusForVendor(
        'c:microsoft-KEq2y6LN',
      ).subscribe(function (consentStatusForVendor) {
        // The consent status for the vendor has changed
        if (consentStatusForVendor === true) {
          window.clarity('consent')
        } else if (
          consentStatusForVendor === false ||
          consentStatusForVendor === undefined
        ) {
          window.clarity('consent', false)
        }
      })

      Didomi.getObservableOnUserConsentStatusForVendor(
        'c:microsoft-2cteJGmA',
      ).subscribe(function (consentStatusForVendor) {
        // The consent status for the vendor has changed
        if (consentStatusForVendor === true) {
          startTelemetry()
        }
      })
    }
  })

  function startTelemetry() {
    const queryStore = useQueryStore($nuxtApp.$pinia)
    const config = useRuntimeConfig()
    if (queryStore.requestGUID) {
      const signalParameter = {
        propertyId: config.public.bpid,
        requestGUID: queryStore.requestGUID,
        instrumentation: {
          pageLoadPingUrl: queryStore.pageLoadPingUrl,
          pingUrlBase: queryStore.pingUrlBase,
        },
      }

      try {
        initTelemetryJS(signalParameter)
      } catch (e) {
        console.log('Telemetry integration failed')
      }
    }
  }

  const tag = (eventName: string, data?: object): void => {
    if (window.clarity) {
      window.clarity('event', eventName, data)
      return
    }

    console.log('Clarity missing')
  }

  // Inject the function globally as $tag
  $nuxtApp.provide('tag', tag)
})
